<template>
  <div class="has-text-centered">
    <figure :class="`image is-${width}x${width} is-inline-block`">
      <img
        src="../assets/images/simsed_logo.png"
        alt="simsed_logo"
        :width="width"
        height="auto"
      />
    </figure>
    <div class="has-text-centered">
      <h1 class="brand-name" v-bind:style="brandName">SIMSED</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brandName: {
        fontSize: '4em',
      },
    }
  },
  props: {
    width: { type: String, default: '96' },
    fontSize: String,
  },
  watch: {
    fontSize(value) {
      this.brandName.fontSize = value
    },
  },
  mounted() {
    this.brandName.fontSize = this.fontSize
  },
}
</script>

<style lang="scss" scoped>
.brand-name {
  font-family: $primary-font;
  color: $accent-color;
}
</style>

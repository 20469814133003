<template>
  <div class="features">
    <section class="hero is-medium">
      <div class="hero-body">
        <div class="columns">
          <div class="column is-10 is-offset-1">
            <div class="columns is-multiline">
              <div class="column is-12">
                <simsed-logo width="48" font-size="1.5em" />
                <h1 class="has-text-centered">
                  School information management application
                </h1>
              </div>
              <div class="column is-12">
                <p class="features-brief">
                  SIMSED is built on a modular concept which allows school's
                  use, adopt or customize the school application based on their
                  needs. The features listed are the core features. We recommend
                  that trying the application will give you a better
                  understanding of the entire features of the school
                  administration or management system.
                </p>
              </div>
              <div
                class="column is-6-tablet is-4-desktop"
                v-for="(item, index) in features"
                :key="index"
              >
                <div class="feature-info has-background-light px-3 py-3">
                  <div class="has-text-centered">
                    <figure class="image is-64x64 is-inline-block">
                      <img
                        :src="require(`@/assets/images/features/${item.image}`)"
                        :alt="`simsed_logo_${index}`"
                        width="64"
                        height="auto"
                      />
                    </figure>
                  </div>
                  <div class="feature-title has-text-centered py-3">
                    {{ item.title }}
                  </div>
                  <div class="feature-desc has-text-justified pt-3">
                    {{ item.desc }}
                  </div>
                </div>
                <!-- .feature-info.bg-light.p-4.mb-4
            .text-center.mb-3
            %img.img-fluid{:alt => "#{feature[:title].downcase} for simsed - unity school management application", :src => "#{asset_pack_path feature[:image]}", width: 70, height: 'auto'}
            %h5.feature-title.text-center
            = feature[:title]
            %hr
            %p.feature-desc.text-justify
            = feature[:desc]
                {{item.desc}}-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SimsedLogo from '../components/SimsedLogo'

export default {
  data() {
    return {
      features: {
        f1: {
          title: 'CENTRAL INFORMATION MANAGEMENT',
          desc:
            'Central storage of all relevant data for single access point operation, monitoring and administration.',
          image: 'central-info.png',
        },
        f2: {
          title: 'E-LEARNING',
          desc:
            'Learning is dynamic, this feature is used for propagation and accessing of knowledge via electronic media.',
          image: 'e-library.png',
        },
        f3: {
          title: 'CONTINUOUS ASSESSMENT MANAGEMENT',
          desc:
            "Teachers don't have to manually prepare results or class – score sheets. This is handled by the application in very simple steps.",
          image: 'continuous-assessment.png',
        },
        f4: {
          title: 'RESULTS PROCESSING',
          desc:
            'Conﬁdently know that the system is computating scores, assigning score remarks and grades accurately for the ﬁnal results.',
          image: 'real-time-results.png',
        },
        f5: {
          title: 'RESULT PUBLISHING',
          desc:
            'The system provides for bulk publishing of results. At the click of a button, you can print out an entire class result.',
          image: 'result-publishing.png',
        },
        f6: {
          title: 'CLOUD BASED DEPLOYMENT',
          desc:
            'Get the information you need wherever you go and whenever you want. Teachers can work from the comfort of their homes on any device.',
          image: 'cloud-based.png',
        },
        f7: {
          title: 'STUDENT ATTENDANCE MANAGEMENT',
          desc:
            'The application has provision for attendance recording. Attendance captured will reflect automatically on the final result for the term.',
          image: 'student-attendance.png',
        },
        f8: {
          title: 'FEES MANAGEMENT',
          desc:
            'Fees are managed by the application allowing parents to view history of payment, check balances and make online payments.',
          image: 'fees.png',
        },
        f9: {
          title: 'SMS INTEGRATION',
          desc:
            'It is useful for quick alerts and notiﬁcations. The SMS can be sent based on pre-defined groups or criteria.',
          image: 'sms.png',
        },
        f10: {
          title: 'EMAIL INTEGRATION',
          desc:
            'Send email messages to all valid users. Final results can be sent to parents via their emails.',
          image: 'email.png',
        },
        f11: {
          title: 'SSL SECURED CONNECTION',
          desc:
            'The application is protected from abuse using a Secure Sockets Layer (SSL). Online security is enhanced by this feature.',
          image: 'ssl.png',
        },
        f12: {
          title: 'TWO FACTOR AUTHENTICATION',
          desc:
            'An additional layer of security to further tighten access to the application. Access code is sent to the user via SMS or Email.',
          image: '2fa.png',
        },
        // f13: {
        //   title: "IDENTITY CUSTOMIZATION",
        //   desc:
        //     "The application supports various customizations that will suit the schools operation. School's can have their \
        //          results, reports and receipts etc customized to their existing or proposed templates. This allows schools to \
        //           maintain their unique identity.",
        //   image: "customizable.png"
        // },
        // f14: {
        //   title: "SCHOOL ACCOUNTING",
        //   desc:
        //     "Schools will be able to keep track of incomes and expenditures as they are accrued. \
        //            Also maintaining a systematic recording of transactions for financial decisions across the management level is made easy .",
        //   image: "accounts.png"
        // },
        // f15: {
        //   title: "PERSONNEL MANAGEMENT",
        //   desc:
        //     "Managing the relationship between employees and the administrators with regards to all work facets and benefits.",
        //   image: "hr.png"
        // },
        // f16: {
        //   title: "ONLINE ACCESS",
        //   desc:
        //     "This feature is aimed at parents and schools. For parents it gives them access to their wards performance and analytics. Also, for the school a CMS added for schools who require a website designed and hosted for them. The CMS will enable direct access to their website for updates.",
        //   image: "online.png"
        // }
      },
    }
  },
  components: {
    SimsedLogo,
  },
}
</script>

<style lang="scss" scoped>
.feature-info {
  min-height: 300px;
}

.feature-title {
  font-family: $primary-font;
  font-size: 0.7em;
  font-weight: bold;
  border-bottom: orange 1px solid;
}

.feature-desc {
  font-family: $secondary-font;
}

.features-brief {
  font-family: $secondary-font;
  text-align: justify;
}

.features-brief-header {
  font-family: $secondary-font;
  font-size: 0.85em;
}
</style>
